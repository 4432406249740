import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import SummaryTable from "../components/summary-table"
import Quote from "../components/quote"
import Gallery from "../components/gallery-section"

import {
  Subheading,
  StyledLink,
  RealWeddingsThirdHeading,
  Copy,
  MenuSubheading,
} from "../components/typography"

const Content = styled.div`
  display: grid;
  /* row-gap: 20px; */
  & > * {
    margin-left: 25px;
    margin-right: 25px;
  }

  & > h2 {
    margin-top: 30px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    & > * {
      margin-left: 30px;
      margin-right: 30px;
    }

    & > h2 {
      margin-top: 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 1110px;
    margin: auto auto 120px;

    & > * {
      margin-left: 0;
      margin-right: 0;
    }

    & > h2 {
      margin-top: 120px;
    }
  }
`

const ExploreMorePost = styled.div`
  display: grid;
  grid-template-columns: 133px 1fr;
  column-gap: 15px;
  border-top: 1px solid hsl(0, 0%, 20%);

  & > * {
    grid-row: 1 / span 2;
    grid-column: 2;
  }

  & > :first-child {
    width: 133px;
    height: 133px;
    object-fit: cover;
    grid-column: 1;
  }

  & > :nth-child(2) {
    margin-top: 15px;
  }

  & > :nth-child(3) {
    grid-row: 2;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 60px;
  }
`

// import ImageAndCopySection from "../components/image-and-copy-section"

const RealWeddingsPost = ({
  // location,
  data: {
    contentfulRealWeddingsPost: {
      seoTitle,
      seoDescription,
      seoKeywords,
      image,
      title,
      subtitle,
      date,
      guests,
      theme,
      content,
      exploreMore,
    },
  },
}) => (
  <Layout>
    <SEO
      title={seoTitle}
      description={seoDescription}
      meta={[{ name: "keywords", content: seoKeywords.join(", ") }]}
    />
    <Header
      contentOnRight={false}
      image={image}
      title={title}
      subtitle={subtitle}
      date={date}
      backLink={
        "/real-weddings/"
        // location
        //   ? location.state
        //     ? location.state.referrer
        //     : undefined
        //   : undefined
      }
    />
    <Content>
      <SummaryTable
        items={[
          { title: "Guests", value: guests },
          { title: "Theme", value: theme },
        ]}
      />
      {content
        ? content.map((contentItem, index) =>
            contentItem.__typename === "ContentfulMenuHeading" ? (
              <div
                key={index}
                style={{
                  marginTop: "60px",
                  maxWidth: "50ch",
                  justifySelf: "center",
                }}
              >
                {contentItem.menuSectionHeading ? (
                  <MenuSubheading>
                    {contentItem.menuSectionHeading}
                  </MenuSubheading>
                ) : null}
                {contentItem.menuSubsectionHeading ? (
                  <RealWeddingsThirdHeading>
                    {contentItem.menuSubsectionHeading}
                  </RealWeddingsThirdHeading>
                ) : null}
                <Copy>
                  {contentItem.menuDescription
                    ? contentItem.menuDescription.menuDescription
                    : null}
                </Copy>
              </div>
            ) : contentItem.__typename === "ContentfulQuote" ? (
              <Quote
                key={index}
                quote={contentItem.quote ? contentItem.quote.quote : null}
              />
            ) : contentItem.__typename === "ContentfulGallery" ? (
              <Gallery
                key={index}
                title={contentItem.title}
                gallery={contentItem.gallery}
              />
            ) : null
          )
        : null}
      {exploreMore ? (
        <>
          <MenuSubheading>Explore More</MenuSubheading>
          {exploreMore.map(({ image, title, slug }, index) => (
            <ExploreMorePost key={index}>
              {image.fluid ? (
                <Img
                  fluid={image.fluid}
                  alt={image.description || "Background Image"}
                />
              ) : image.file.url ? (
                <img
                  src={image.file.url}
                  alt={image.description || "Background Image"}
                />
              ) : (
                <div />
              )}
              <Subheading>{title}</Subheading>
              <StyledLink to={`/real-weddings/${slug}/`}>Read More</StyledLink>
            </ExploreMorePost>
          ))}
        </>
      ) : null}
    </Content>
  </Layout>
)

export default RealWeddingsPost

RealWeddingsPost.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    contentfulRealWeddingsPost: PropTypes.shape({
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.string,
      seoKeywords: PropTypes.array,
      image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
        fluid: PropTypes.object,
      }),
      title: PropTypes.string,
      subtitle: PropTypes.string,
      date: PropTypes.string,
      guests: PropTypes.string,
      theme: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string,
          menuSectionHeading: PropTypes.string,
          menuSectionDescription: PropTypes.shape({
            menuSectionDescription: PropTypes.string,
          }),
        }),
        PropTypes.shape({
          __typename: PropTypes.string,
          quote: PropTypes.shape({
            quote: PropTypes.string,
          }),
        }),
        PropTypes.shape({
          __typename: PropTypes.string,
          title: PropTypes.string,
          gallery: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              file: PropTypes.shape({
                url: PropTypes.string,
                details: PropTypes.shape({
                  image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                  }),
                }),
              }),
              fluid: PropTypes.object,
            })
          ),
        })
      ),
      exploreMore: PropTypes.arrayOf(
        PropTypes.shape({
          gallery: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              file: PropTypes.shape({
                url: PropTypes.string,
                details: PropTypes.shape({
                  image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                  }),
                }),
              }),
              fluid: PropTypes.object,
            })
          ),
          title: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
    }),
  }),
}

export const query = graphql`
  query RealWeddingQuery($slug: String!) {
    contentfulRealWeddingsPost(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      seoKeywords
      image {
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      title
      subtitle
      date
      guests
      theme
      content {
        ... on ContentfulMenuHeading {
          menuSectionHeading
          menuSubsectionHeading
          menuDescription {
            menuDescription
          }
        }
        ... on ContentfulQuote {
          quote {
            quote
          }
        }
        ... on ContentfulGallery {
          title
          gallery {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      exploreMore {
        image {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        title
        slug
      }
    }
  }
`
